import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class RestProvider {

  apiUrl: any = environment.apiUrl;

  constructor(public http: HttpClient) {
  }

  getToken()
  {
    var token = "";
    if(localStorage.getItem("userDetails"))
    {
      token = 'Bearer '+JSON.parse(localStorage.getItem("userDetails")).token;
    }
    return token;
  }

  signup(body) {
    // var token = JSON.parse(localStorage.getItem("userDetails")).token;
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl+"user/register", body, {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  login(body) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl+"user/login", body, {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  otp(body) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl+"user/verify-register-otp", body, {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  payStripeNow(body) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl+"payment", body, {headers: new HttpHeaders({ "Authorization": this.getToken()})})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  payStripe(body) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl+"payment", body, {headers: new HttpHeaders({ "Authorization": this.getToken()})})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  payStripeDirect(body) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl+"payment/stripe-customer", body, {headers: new HttpHeaders({ "Authorization": this.getToken()})})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  createCard(body) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl+"order/create-gift-card", body, {headers: new HttpHeaders({ "Authorization": this.getToken()})})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  createOrder(body) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl+"order/create-order-detail", body, {headers: new HttpHeaders({ "Authorization": this.getToken()})})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  updateUser(body) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl+"user/update", body, {headers: new HttpHeaders({ "Authorization": this.getToken()})})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  setPassword(body) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl+"user/check-pass-code", body, {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  forgotPass(email) {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl+"user/forgot-password?email="+email, {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getUser() {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl+"user?token="+JSON.parse(localStorage.getItem("userDetails")).token, {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getOrderList() {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl+"order/get-listing?token="+JSON.parse(localStorage.getItem("userDetails")).token, {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getOrderDetails(id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl+"order/get-detail?token="+JSON.parse(localStorage.getItem("userDetails")).token+"&order_id="+id, {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getGiftList() {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl+"gift/get-listing?token="+JSON.parse(localStorage.getItem("userDetails")).token, {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getGiftDetails(id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl+"gift/get-detail?token="+JSON.parse(localStorage.getItem("userDetails")).token+"&order_id="+id, {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getOrderByCode(body){
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl+"order/get-order-code", body, {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  uploadImg(body) {
    // this.http.put(this.userApiUrl+"user/update/user/", body, {headers: new HttpHeaders({ "Authorization": 'Bearer '+token })})
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl+"order/upload-image", body, {headers: new HttpHeaders({ "Authorization": this.getToken()})})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getUserByCode(userCode){
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl+"user/code?user_code="+userCode, {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  completeReg(body) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl+"user/update-guest-user", body, {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
  
  getTimezones(){
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl+"comms/time-zones", {})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

}
